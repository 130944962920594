import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import { fetchAppVersion } from 'src/features/appVersion';
import { actions as authActions, selectors as authSelectors } from 'src/features/auth/authSlice';
import { fetchCurrentUser } from 'src/features/auth/services';
import { getFeatureFlagIsIdle, getFeatureFlagIsLoading } from 'src/features/featureFlag';
import { useGetFeatureFlag } from 'src/features/featureFlag/hooks/useGetFeatureFlag';
import { selectors as propertySelectors } from 'src/features/property';
import { configVars } from 'src/helpers';
import Router from 'src/router';
import { useAppDispatch } from 'src/store';
import { FeatureFlagKey } from 'src/ts/enums';
import { authApi } from './api';
import useProperty from './features/property/hooks/useProperty';

const COOKIE_MIGRATE_KEY = 'fraud_platform_cookie_migrate';
const isCookieMigrated = () => localStorage.getItem(COOKIE_MIGRATE_KEY) != null;
const markMigrated = () => localStorage.setItem(COOKIE_MIGRATE_KEY, Date.now().toString());

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const userData = useSelector(authSelectors.user);
  const userIsIdle = useSelector(authSelectors.isIdle);
  const userIsLoading = useSelector(authSelectors.isLoading);

  const featureFlagIsIdle = useSelector(getFeatureFlagIsIdle);
  const featureFlagIsLoading = useSelector(getFeatureFlagIsLoading);

  const { onGetIdentityVerificationPropertyEnabled } = useProperty();

  const getIdentityVerificationPropertyEnabledIsIdle = useSelector(
    propertySelectors.hasIdentityVerificationPropertyEnabled.isIdle
  );
  useEffect(() => {
    if (userData && getIdentityVerificationPropertyEnabledIsIdle) {
      onGetIdentityVerificationPropertyEnabled();
    }
  }, [
    userData,
    getIdentityVerificationPropertyEnabledIsIdle,
    onGetIdentityVerificationPropertyEnabled
  ]);

  const hasIdentityVerificationPropertyEnabled = useSelector(
    propertySelectors.hasIdentityVerificationPropertyEnabled.data
  );

  useEffect(() => {
    const authRefresh = async () => {
      if (userData && !isCookieMigrated()) {
        const { pathname } = window.location;
        markMigrated();
        if (pathname === '/login') {
          dispatch(authActions.logout());
        } else {
          await authApi.refreshSession();
          dispatch(fetchCurrentUser({ isRefreshSessionCaller: true }));
        }
      }
    };
    authRefresh().catch(console.error);
  }, [userData, dispatch]);

  useEffect(() => {
    if (userIsIdle && !userData) {
      dispatch(fetchCurrentUser());
      dispatch(fetchAppVersion());
    }
  }, [userIsIdle, userData, dispatch]);

  const { onGetFeatureFlagValue } = useGetFeatureFlag();

  useEffect(() => {
    if (!userIsIdle && !userIsLoading && userData) {
      onGetFeatureFlagValue(FeatureFlagKey.CLEAN_PROCEED_WITH_CAUTION, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.EXTENDED_FA_DOCUMENT_TYPES, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.NEW_REPORT_ENDPOINT, `role=${userData.role}`);
      onGetFeatureFlagValue(
        FeatureFlagKey.INCOME_VERIFICATION_PROPERTY_TOGGLE,
        `role=${userData.role}`
      );
      onGetFeatureFlagValue(FeatureFlagKey.INCOME_VERIFICATION, `role=${userData.role}`);
      onGetFeatureFlagValue(
        FeatureFlagKey.MONTHLY_GROSS_INCOME_FDE_INBOX_COLUMN,
        `role=${userData.role}`
      );
      onGetFeatureFlagValue(
        FeatureFlagKey.MONTHLY_GROSS_INCOME_FOLDER_REVIEW,
        `role=${userData.role}`
      );
      onGetFeatureFlagValue(FeatureFlagKey.DISPUTE_BUTTON_FOLDER_REVIEW, `role=${userData.role}`);
      onGetFeatureFlagValue(FeatureFlagKey.REPORT_PAGE_REDESIGN, `role=${userData.role}`);
    }
  }, [userIsIdle, userIsLoading, userData, onGetFeatureFlagValue]);

  useEffect(() => {
    const { pathname } = window.location;

    const isDocumentPortal = pathname.includes('application/apply');
    if (isDocumentPortal) {
      const favicon = document.getElementById('favicon') as HTMLAnchorElement;
      favicon.href = '/assets/images/DocumentIcon.svg';
      document.title = 'Document Portal';
    }
  });

  //Ensure load of the data required for the nav options to render the ID Verification Link tab correctly
  if (
    ((userIsIdle || userIsLoading) && (featureFlagIsIdle || featureFlagIsLoading)) ||
    (!userIsIdle && !userIsLoading && userData && hasIdentityVerificationPropertyEnabled === null)
  ) {
    return null;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script type="text/javascript">
            {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load("${configVars.heap.app_id}");`}
          </script>
        </Helmet>
      </HelmetProvider>

      <Router />
    </>
  );
};

export default App;
