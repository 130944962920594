import { ProofRequest } from '@snapptinc/fraud-platform';

import { proofApi } from 'src/api';
import { eligibleDocuments } from 'src/pages/IncomeVerificationFlowPage/IncomeVerificationFlowPage';
import { IProof } from 'src/ts/interfaces';

const api = {
  getProofsByEntryId(entryId: string) {
    return proofApi.getProofs(`entry_id=${entryId}`, `id:desc`);
  },

  getIncomeVerificationProofsByEntryId(entryId: string) {
    const eligibleDocumentsQueryString = eligibleDocuments.join('|');
    return proofApi.getProofs(
      `entry_id=${entryId},result=CLEAN,type@>${eligibleDocumentsQueryString}`,
      `id:desc`
    );
  },
  getProofById(proofId: string) {
    return proofApi.getProof(proofId);
  },
  updateProof(proofId: string, proof: Partial<IProof>) {
    return proofApi.updateProof(proofId, { proof } as unknown as ProofRequest);
  }
};

export default api;
